var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.page.split('/')[4] !== 'dashboard')?_c('CBreadcrumb',{staticStyle:{"margin-top":"-28px"},attrs:{"items":_vm.links}}):_vm._e(),_c('CCard',[_c('CCardBody',[_c('TheTableHeader',{attrs:{"header":'RENCANA KUNJUNGAN',"subHeader":'DAFTAR PERMINTAAN KUNJUNGAN INSTANSI/PERUSAHAAN KE RUANGAN DI DATA CENTER LINTASARTA.',"buttonShow":"","buttonText":'Buat Pengajuan Baru',"buttonUrl":'/visitors/new-request'}})],1)],1),_c('CCard',[_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","small":"","tableFilter":"","sorter":"","itemsPerPageSelect":"","items":_vm.visits,"fields":_vm.fields,"items-per-page":10,"pagination":"","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.visits.map(function(x) {return x.id; }).indexOf(item.id)+1))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" "),_c('strong',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at)))])])]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm._f("formatTime")(item.start_date)))])])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.end_date))+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm._f("formatTime")(item.end_date)))])])]}},{key:"user_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(_vm._s(item.user_id)+" "),_c('br'),_vm._v(" Access card: "),_c('strong',[_vm._v(_vm._s(item.access_card_number))])])])]}},{key:"room_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(_vm._s(item.room_name)+" "),_c('br'),_vm._v(" Cage/Rack: "),_c('strong',[_vm._v(_vm._s(item.rack))])])])]}},{key:"shipment_status",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.shipment_status === 'Yes' ? 'Ada' : 'Tidak'))])]}},{key:"site_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.site_name))])]}},{key:"company_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.company_name))])]}},{key:"activity_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticStyle:{"cursor":"pointer","color":"blue"},on:{"click":function($event){return _vm.detailVisit(item.id)}}},[_vm._v(_vm._s(item.activity_name))]),_c('br'),_c('small',[_vm._v("Prioritas "+_vm._s(item.priority === 1 ? 'Tinggi' : 'Normal'))])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status === 'Pending')?_c('CBadge',{staticClass:"animate__animated animate__flash animate__infinite",attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status === 'Cancel' ? 'Canceled' : item.status))]):_vm._e(),(item.status !== 'Pending')?_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status === 'Cancel' ? 'Canceled' : item.status))]):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[(item.status !== 'Cancel' && item.status !== 'Closed')?_c('CSelect',{attrs:{"disabled":_vm.role > 5 || _vm.role === 4 && item.status === 'On-Going',"value":item.status,"name":"approval","size":"sm","options":_vm.role === 4 ? item.status === 'Draft' ? _vm.approvalPic : _vm.monitPic : _vm.role === 5 ? _vm.approvalRec : item.status === 'On-Going' ? _vm.afterRunning : _vm.approval},on:{"update:value":function($event){return _vm.$set(item, "status", $event)},"change":function($event){return _vm.changeStatus(item, $event)}}}):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }