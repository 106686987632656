<template>
<div>
	<CBreadcrumb v-if="page.split('/')[4] !== 'dashboard'" :items="links" style="margin-top: -28px;"/>

	<CCard>
    <CCardBody>
			<TheTableHeader
				:header="'RENCANA KUNJUNGAN'"
				:subHeader="'DAFTAR PERMINTAAN KUNJUNGAN INSTANSI/PERUSAHAAN KE RUANGAN DI DATA CENTER LINTASARTA.'"
				buttonShow
				:buttonText="'Buat Pengajuan Baru'"
				:buttonUrl="'/visitors/new-request'"
			/>
		</CCardBody>
	</CCard>

	<CCard>
		<CCardBody>
			<CDataTable
				hover
				striped
				border
				small
				tableFilter
				sorter
				itemsPerPageSelect
				:items="visits"
				:fields="fields"
				:items-per-page="10"
				pagination
				:loading="isLoading"
			>
				<!-- <template #registeredTimestamp-filter="{item}">
        	From:
        	<input
          	type="date"
          	:value="new Date(startDate).toISOString().substr(0, 10)"
          	@change="setDateFilter"
          	class="mr-2"
        	/>
        	To:
        	<input
          	type="date"
          	:value="new Date(endDate).toISOString().substr(0, 10)"
          	@change="e => setDateFilter(e, 'end')"
        	/>        
      	</template> -->
				<!-- <template #status="{item}">
					<td>
						<CBadge :color="getBadge(item.inActive)">{{item.inActive}}</CBadge>
					</td>
				</template> -->
				<template #id="{item}">
					<td align="center">{{visits.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
				</template>
				<template #created_at="{item}">
					<td>
						{{item.created_at | formatDate}} 
						<strong>{{item.created_at | formatTime}}</strong></td>
				</template>
				<!-- <template #start_date="{item}">
					<td>
						{{item.start_date | formatDate}}
						<br>
						<strong>{{item.start_date | formatTime}}</strong> to <strong>{{item.end_date | formatTime}}</strong></td>
				</template> -->
				<template #start_date="{item}">
					<td>
						{{item.start_date | formatDate}}
						<br>
						<strong>{{item.start_date | formatTime}}</strong></td>
				</template>
				<template #end_date="{item}">
					<td>
						{{item.end_date | formatDate}}
						<br>
						<strong>{{item.end_date | formatTime}}</strong></td>
				</template>
				<template #user_id="{item}">
					<td>
						<small>{{item.user_id}}
						<br>
						Access card: <strong>{{item.access_card_number}}</strong></small></td>
				</template>
				<template #room_name="{item}">
					<td>
						<small>{{item.room_name}}
						<br>
						Cage/Rack: <strong>{{item.rack}}</strong></small></td>
				</template>
				<template #shipment_status="{item}">
					<td>{{item.shipment_status === 'Yes' ? 'Ada' : 'Tidak'}}</td>
				</template>
				<template #site_name="{item}">
					<td>{{item.site_name}}</td>
				</template>
				<template #company_name="{item}">
					<td>{{item.company_name}}</td>
				</template>
				<template #activity_name="{item}">
					<td>
						<a style="cursor: pointer; color: blue;" @click="detailVisit(item.id)">{{item.activity_name}}</a>
						<br><small>Prioritas {{item.priority === 1 ? 'Tinggi' : 'Normal'}}</small>
					</td>
				</template>
				<template #status="{item}">
					<td>
						<CBadge v-if="item.status === 'Pending'" :color="getBadge(item.status)" class="animate__animated animate__flash animate__infinite">{{item.status === 'Cancel' ? 'Canceled' : item.status}}</CBadge>
						<CBadge v-if="item.status !== 'Pending'" :color="getBadge(item.status)">{{item.status === 'Cancel' ? 'Canceled' : item.status}}</CBadge>
					</td>
				</template>
				<template #action="{item}">
      		<td align="center">
						<CSelect
							v-if="item.status !== 'Cancel' && item.status !== 'Closed'"
							:disabled="role > 5 || role === 4 && item.status === 'On-Going'"
							:value.sync="item.status"
							name="approval"
							size="sm"
							:options="role === 4 ? item.status === 'Draft' ? approvalPic : monitPic : role === 5 ? approvalRec : item.status === 'On-Going' ? afterRunning : approval"
							@change="changeStatus(item, $event)"
						/>
      		</td>
    		</template>
			</CDataTable>
		</CCardBody>
	</CCard>
</div>
</template>

<script>
import http from '@/utils/http-common';
import CTableWrapper from '../tables/Table.vue';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableVisitRequest',
	components: { CTableWrapper, TheTableHeader },
	data () {
		return {
			page: window.location.href,
			site_id: +JSON.parse(localStorage.getItem('user'))['user']['site_id'],
			role: +localStorage.getItem('role'),
			company_id: +localStorage.getItem('company_id'),
			visits: [],
			approval: [
				'Pending', 'Reserved', 'On-Going', 'Canceled', 'Closed'
			],
			approvalRec: [
				'Pending', 'Reserved', 'On-Going', 'Closed'
			],
			approvalPic: [
				'Draft', 'Approve', 'Reject', 'Canceled'
			],
			monitPic: [
				'Pending', 'Canceled'
			],
			afterRunning: [
				'On-Going', 'Closed'
			],
			fields: [
				{ key: 'id', label: 'No' },
				{ key: 'created_at', label: 'Request At' },
				{ key: 'company_name', label: 'Company' }, 
				{ key: 'user_id', label: 'Requester' },
				{ key: 'start_date', label: 'Start' }, 
				{ key: 'end_date', label: 'End' }, 
				// { key: 'priority', label: 'Priority' }, 
				{ key: 'site_name', label: 'Data Center' }, 
				{ key: 'room_id', label: 'Room' }, 
				{ key: 'shipment_status', label: 'Shipment' }, 
				'status',
				{ key: 'activity_name', label: 'Detail' }, 
				{ key: 'action', label: 'Approval' }, 
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 1,
			isLoading: true,
			links: [
      	{
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Pengajuan Kunjungan'
        }
      ]
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message) {
      this.$toast.open({
        message: message,
        type: "info", // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		getBadge (status) {
			return status === 'Pending' ? 'danger' 
				: status === 'On-Going' ? 'warning' 
				: status === 'Cancel' ? 'dark' 
				: status === 'Closed' ? 'success' 
				: status === 'Draft' ? 'info'
				: status === 'Reserved' ? 'info' 
				: 'primary'
		},
		getVisits() {
			let self = this;
			return http.get('/visits')
				.then(function (response) {
					let data = response.data.data;
					self.isLoading = false;

					// Operator & Receptionist
					if (self.role == 5 || self.role == 3) {
						self.visits = data.filter(function(item) {
    			  	return item.status !== 'Draft' && +item.site_id == +self.site_id;  
				  	});
					} else 
					// PIC Manager
					if (self.role == 4) {
						self.visits = data.filter(function(item) {
    			  	return +item.company_id == +self.company_id;  
				  	});
					}  else 
					// Visitor
					if (self.role > 5) {
						self.visits = data.filter(function(item) {
    			  	return +item.company_id == +self.company_id;  
				  	});
					} else 
					// Admin & Supervisor
					if (self.role <= 5 && self.role !== 4) {
						// self.visits = data;
						self.visits = data.filter(function(item) {
    			  	return item.status !== 'Draft';  
				  	});
					}
					
					/* switch (self.role) {
        		case 1: 
							self.visits = data.filter(function(item) {
    			  		return item.status !== 'Draft';  
				  		});
        		case 2: 
							self.visits = data.filter(function(item) {
    			  		return item.status !== 'Draft';  
				  		});
        		case 3: 
							self.visits = data.filter(function(item) {
    			  		return item.status !== 'Draft' && item.site_id === self.site_id;  
				  		});
						case 4: 
							self.visits = data.filter(function(item) {
    			  		return item.status === 'Draft' && item.company_id === self.company_id;  
				  		});
        		case 5: 
							self.visits = data.filter(function(item) {
    			  		return item.status !== 'Draft' && item.site_id === self.site_id;  
				  		});
        		default: 
							self.visits = data.filter(function(item) {
    			  		return item.company_id === self.company_id;  
				  		});
      		} */

					// console.log(self.visits)

				}).catch(function (error) {
					console.log(error);
					self.isLoading = false;
				});
		},
		watcher() {
			let self = this;
			self.isLoading = true;

			self.getVisits();
			setInterval(function() {
        // your code goes here...
        self.getVisits()
      }, 180 * 1000); // every 3 minutes
		},
		changeStatus(item, event) {
			let self = this;

			let status = null;
			if (event.target.value === 'Approve') {
				status = 'Pending';
			} else if (event.target.value === 'Reject') {
				status = 'Cancel';
			} else {
				status = event.target.value; 
			}

			return http.post('/visits/update/' + item.id, {
				status: status,
			})
			.then(function (response) {
				self.$store.dispatch('notified')
				.then(() => {
					self.toast('Status diubah (' + status + ')')
				})
				.catch(function (error) {
        	console.log(error);
      	});
			}).catch(function (error) {
				console.log(error);
			});
		},
		detailVisit(id) {
      this.$router.push({ path: this.page.split('/')[4] === 'dashboard' ? '/approval/visit-request/' + id : 'visit-request/' + id });
    },
	},
	mounted () {
		// console.log(this.page.split('/')[4])
		this.watcher();
		// this.isLoading = false;
	},
}
</script>
