<template>
  <div>
    <CRow>
			<CCol md="8">
				<h2>{{header}}</h2>
				<p>{{subHeader}}</p>
			</CCol>
			<CCol md="4" class="col align-self-center row justify-content-end">
				<CButton
          v-if="buttonShow"
          :disabled="role > 5"
          :color="role > 5 ? 'dark' : 'success'"
          variant="outline"
          square
          size="sm"
           @click="goTo()"
        >
          {{buttonText}}
        </CButton>
			</CCol>
		</CRow>
  </div>
</template>
<script>
export default {
  name: 'TheTableHeader',
  data () {
		return {
			page: window.location.href,
      role: +localStorage.getItem('role'),
    }
  },
  props: {
    isHeader: {
      default: false,
      type: Boolean,
      required: false
    },
    header: {
      default: '',
      type: String,
      required: true
    },
    subHeader: {
      default: '',
      type: String,
      required: true
    },
    buttonShow: {
      default: false,
      type: Boolean,
      required: false
    },
    buttonText: {
      default: '',
      type: String,
      required: false
    },
    buttonUrl: {
      default: '',
      type: String,
      required: false
    },
  },
  methods: {
    goTo() {
      let self = this;
      this.$router.push({ path: self.buttonUrl });
    }
  }
}
</script>

<style scoped>
.btn {
  position: absolute;
  top: 0;
}
</style>